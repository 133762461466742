import { useState, useEffect } from "react";
import { connect } from "react-redux";
import Header from "./components/Header";
import Loader from "../sharedComponents/Loader";
import { getCampagin } from "@/redux/actions/commonActions";
import stl from "./Layout.module.css";
import { withTranslation2 } from "@i18n";
import WithRouter from "../../common/WithRouter";
import loadable from "@loadable/component";
import useIsFirstInteraction from "../../common/hooks/useIsFirstInteraction";
import useIsWebView from "@hooks/useIsWebview";
import { useLang } from "@/common/Contexts/LanguageContext";
import useIsMobile from "@hooks/useIsMobile";
import useIsIOS from "@hooks/useIsIOS";
import { CDN_LINK } from "@globalConfig";


let IS_LOADED_TIME = 1500;

const fallback = <>Loading...</>;
const NewAside = loadable(
  () => import("./components/NewAside/NewAside"),
  fallback
);
const MobileFooter = loadable(
  () => import("./components/MobileFooter/MobileFooter"),
  fallback
);
const Footer = loadable(() => import("./components/Footer"), fallback);
const NavBar = loadable(() => import("./components/NavBar/NavBar"), fallback);

const ContactUsPopUp = loadable(
  () => import("./components/ContactUsPopUp/ContactUsPopUp"),
  fallback
);

function LayoutRaw(props) {
  const { contact } = props;
  const isWebView = useIsWebView();
  const isIos = useIsIOS();
  const lang = useLang();
  const isMobile = useIsMobile();
  const isFirstInteraction = useIsFirstInteraction();
  const isTracking = !!props.tracking;

  const [showMenu, setShowMenu] = useState(false);

  const [showPopCampaign, setShowPopCampaign] = useState(
    props.showPopCampaign ? props.showPopCampaign : false
  );

  // Replicate componentDidMount
  useEffect(() => {
    setDocHeight();
    window.addEventListener("resize", setDocHeight);
    window.addEventListener("orientationchange", setDocHeight);

    if (
      props.campaginData !== "stopReq" &&
      showPopCampaign &&
      Object.keys(props.campaginData).length === 0
    ) {
      props.getCampagin("");
    }

    return () => {
      window.removeEventListener("resize", setDocHeight);
      window.removeEventListener("orientationchange", setDocHeight);
    };
  }, []);

  useEffect(() => {
    setShowPopCampaign(props.showPopCampaign ? props.showPopCampaign : false);
  }, [props.showPopCampaign]);

  const createLogoLanguageLink = () => {
    return lang === "en" ? `/en` : "/";
  };

  const setDocHeight = () => {
    document.documentElement.style.setProperty(
      "--vh",
      `${window.innerHeight / 100}px`
    );
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const [isLoaded, setIsLoaded] = useState(false);
  
  if (__IS_CSR__) {
    IS_LOADED_TIME = window.location.search.split('IS_LOADED_TIME=')?.[1] || IS_LOADED_TIME;
  }

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, IS_LOADED_TIME);
  }, []);

  return (
    <>
      <div
        className={[
          props.authenticationMsg ? "hasAuthPad" : undefined,
          props.hasNavBar ? stl.hasNavBar : undefined,
          isWebView ? "webview" : "",
          __IS_CSR__ && isLoaded ? "isLoaded" : "",
          isTracking ? "isTracking" : "",
          isIos ? "IOS" : "",
          "MainContainerLO",
        ].join(" ")}
      >
        {!props.disableLoader && props.loading && <Loader />}
        {props.hasWebviewClose && (
          <a className={"closeWebView leftCloseTest"} href="app://close">
            <img
              src={CDN_LINK + "/assets/images/closeBslider.svg"}
              alt="close"
            />
          </a>
        )}

        <Header
          setUnbxdPage={props.setUnbxdPage}
          showSearchBar={props.showSearchBar}
          hideBannerCssOnly={props.hideBannerCssOnly}
          custommobileHeader={props.custommobileHeader}
          custommobileHeaderFragmentOnly={props.custommobileHeaderFragmentOnly}
          createLogoLanguageLink={createLogoLanguageLink()}
          mobileHasBack={props.mobileHasBack}
          homeSearchHeader={props.homeSearchHeader}
          searchSearchHeader={props.searchSearchHeader}
          deal={props.deal}
          loading={props.loading}
          hideAdvSearchMobile={props.hideAdvSearchMobile}
          selectedTab={
            props.searchSelectedTab !== undefined
              ? props.searchSelectedTab
              : props.selectedTab
          }
          updateTabs={props.updateTabs}
          headHideLinks={props.headHideLinks}
          mobileHasTitle={props.mobileHasTitle}
          mobileNoLogo={props.mobileNoLogo}
          mobileNoLogoHasBack={props.mobileNoLogoHasBack}
          toggleMenu={toggleMenu}
          loggedin={props.loggedin}
          notification={props.notification}
          getSearchSuggestions={props.getSearchSuggestions}
          resetSearchFilters={props.resetSearchFilters}
          searchParams={props.searchParams}
          hideOrangeHeadBtn={props.hideOrangeHeadBtn}
          hasCampaign={props.showPopCampaign}
          campData={props.campaginData}
          burgrMenuisCncl={props.burgrMenuisCncl}
          logoNotClickable={props.logoNotClickable}
          hideMenu={props.hideMenu}
          revampedHEading={props.revampedHEading}
          revampedSearch={props.revampedSearch}
          tracking={props.tracking}
          contact={contact}
          linksForSearch={props.linksForSearch}
          hideSideMenue={props.hideSideMenue}
          user={props.user}
          hideAppBanner={props.hideAppBanner}
          className={props.headerClassName}
          hideBurgerMenu={props.hideBurgerMenu}
          hideHeaderOnmobile={props.hideHeaderOnmobile}
          straightHeader={props.straightHeader}
        />

        <main
          className={[
            props.mainClass ? props.mainClass : undefined,
            props.showPopCampaign &&
              props.campaginData &&
              props.campaginData.title
              ? "hasCamp"
              : undefined,
          ].join(" ")}
        >
          {props?.children}
        </main>
        {!isMobile && !props.hideFooter && (
          <Footer
            hideFooterWithoutAuthMsg={props.hideFooterWithoutAuthMsg}
            authenticationMsg={props.authenticationMsg}
            createLogoLanguageLink={createLogoLanguageLink()}
          />
        )}

        {isMobile && props.hasMobFooter && (
          <div className="m-show">
            <MobileFooter />
          </div>
        )}

        {isMobile && props.hasNavBar && <NavBar customStikeyButton={props.customStikeyButton} {...props} />}
      </div>

      {(!isMobile || isFirstInteraction) && (
        <NewAside
          createLanguageSwitcherLink={props.createLanguageSwitcherLink}
          user={props.user}
          showMenu={showMenu}
          toggleMenu={toggleMenu}
          loggedin={props.loggedin}
        />
      )}

      <div id="popUpId" />

      {props.hasContactUs && isFirstInteraction && (
        <ContactUsPopUp {...props} />
      )}
    </>
  );
}
const mapStateToProps = (state) => ({
  loading: state.common.loading,
  searchParams: state.search.searchParams,
  campaginData: state.common.campaginData,
  selectedTab: state.home.selectedTab,
  user: state.auth.user,
  loggedin: state.auth.loggedin,
});

const Layout = withTranslation2(["web_common"])(
  connect(mapStateToProps, { getCampagin })(WithRouter(LayoutRaw))
);
export default Layout;
