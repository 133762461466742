import BoxLoading from "../SearchComponent/components/BoxLoading";
import stl from "./HomePage.module.css";

function HomePageLoader() {

    return (<>
            <div className={[stl.heroLoader].join(" ")}>
                <BoxLoading divHeight="100%" numberOfLoaders="1"/>
            </div>

            <div className={[stl.paymentsLoader].join(" ")}>
                <BoxLoading divHeight="100%" numberOfLoaders="1"/>
            </div>

            <div className={[stl.CustomContainer, stl.boxesContainer].join(" ")}>
                <BoxLoading divHeight="209px" numberOfLoaders="8"/>
            </div>
        </>
    );
}

export default HomePageLoader;
