import React from "react";
var __jsx = React.createElement;
import stl from "./SlideContent.module.css";
import ProgressiveImage from "@componentsShared/ProgressiveImage";
var SlideContent = function SlideContent(_ref) {
  var title = _ref.title,
    items = _ref.items,
    isEager = _ref.isEager,
    titleClass = _ref.titleClass,
    containerClass = _ref.containerClass,
    has3 = _ref.has3;
  return __jsx("div", {
    className: stl.pCont
  }, __jsx("div", {
    className: titleClass
  }, isEager ? __jsx("h1", {
    className: "big"
  }, title) : __jsx("h2", {
    className: "big"
  }, title)), __jsx("div", {
    className: [containerClass || "", stl.container, has3 ? stl.has3 : ""].join(" ")
  }, items === null || items === void 0 ? void 0 : items.map(function (item, idx) {
    return __jsx("div", {
      key: idx,
      className: stl.cardContainer,
      style: {
        background: item.bgColor,
        opacity: item.hide ? "0" : "1"
      }
    }, __jsx(ProgressiveImage, {
      src: item.imageUrl,
      progressiveSmallImage: item.progressiveImageUrl,
      width: 267,
      height: 267,
      loading: isEager ? "eager" : "lazy",
      fetchpriority: isEager ? "high" : "low",
      alt: item.title || item.imageUrl
    }), item.title && __jsx("strong", null, item.title), item.text && __jsx("p", null, item.text));
  })));
};
export default SlideContent;