import fetcher from "../../common/axiosInstance";
import { loading } from "./commonActions";
import {
  GET_SEARCH_FILTERS,
  SET_MODELS_EXTENTIONS,
  GET_SEARCH_SUGGESTIONS,
  GET_INITIAL_SEARCH_SUGGESTIONS,
} from "../types";

import {
  extractExtentions,
  getYearsArray,

} from "../../utils/common";


/** Get search params  */
export const getSearchFilters =
  (route, onload = false, req) =>
  async (dispatch) => {
    dispatch(loading(true));
    const res = await fetcher(req).get(
      "/post/search-params?include=optionsList"
    );
    if (res.data && res.data.success) {
      dispatch({
        type: GET_SEARCH_FILTERS,
        payload: res.data.data,
      });
      if (onload) await checkValidUrls(dispatch, route, res.data.data);
    }
    dispatch(loading(false));
  };


/** Get models and extensions for each make */
export const getSearchSuggestions =
  (keyword = "", callBack = null, initialData = false) =>
  async (dispatch) => {
    const res = await fetcher().get(`/post/suggestions?keyword=${keyword}`);
    if (res.data && res.data.success) {
      if (initialData) {
        dispatch({
          type: GET_INITIAL_SEARCH_SUGGESTIONS,
          payload: res.data.data,
        });
      } else
        dispatch({
          type: GET_SEARCH_SUGGESTIONS,
          payload: res.data.data,
        });
    }
    if (callBack) callBack();
  };



/** Check valid urls for search params action */
export const checkValidUrls = async (dispatch, route, data) => {
  let make, year, city;
  let params = [];
  let urlParams = getUrlParams(route);
  const years = getYearsArray(data.years.values);
  for (let index = 0; index < urlParams.length; index++) {
    if (!make) {
      make = getParamValue(data.makes.values, urlParams[index]);
      if (make) {
        params.push(make.name.toString().replace(/ /g, "_"));
        const res = await fetcher().get(
          `/post/get-models-extentions?make_id=${make.id}`
        );
        if (res.data && res.data.success) {
          if (urlParams[index + 1]) {
            const model = res.data.data.models.values.find(
              (model) => model.name == urlParams[index + 1].replace(/_/g, " ")
            );
            if (model) {
              params.push(model.name.toString().replace(/ /g, "_"));
              if (urlParams[index + 2]) {
                let extensions = extractExtentions(
                  model.id,
                  res.data.data.models
                );
                if (extensions.values.length > 0) {
                  const extension = extensions.values.find(
                    (ext) =>
                      ext.name.toLowerCase() ==
                      urlParams[index + 2].toLowerCase()
                  );
                  if (extension)
                    params.push(extension.name.toString().replace(/ /g, "_"));
                }
              }
              dispatch({
                type: SET_MODELS_EXTENTIONS,
                payload: res.data.data,
              });
            }
          }
        }
        continue;
      }
    }
    if (!city) {
      city = getParamValue(data.cities.values, urlParams[index]);
      if (city) {
        params.push(city.name);
        continue;
      }
    }
    if (!year) {
      year = getParamValue(years, urlParams[index]);
      if (year) {
        params["year_from"] = year;
        params.push(year);
        continue;
      }
    }
  }

};

// Get url params splited by / or -
const getUrlParams = (route) => {
  let dashParams = [];
  let urlParams = decodeURI(route).split("/");
  urlParams.forEach((element, idx) => {
    if (element.includes("-")) {
      let dashSplited = decodeURI(element).split("-");
      dashSplited.forEach((row) => {
        dashParams.push(row);
      });
      urlParams.splice(idx, 1);
    }
  });
  urlParams = [...urlParams, ...dashParams];
  return urlParams;
};


//Extract if value exist in params array
const getParamValue = (values, urlString) => {
  let param;
  if (values.length)
    param = values.find((item) =>
      typeof item == "object"
        ? item.name == urlString.replace(/_/g, " ")
        : item == urlString
    );

  return param;
};
