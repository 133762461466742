import React, { Component } from "react";
import style from "./BoxLoading.module.css";

class BoxLoading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberOfLoaders: Array.from(
        { length: this.props.numberOfLoaders },
        () => ""
      ),
      height: this.props.divHeight,
    };
  }

  render() {
    return this.state.numberOfLoaders.map((item, i) => (
      <div
        key={i}
        style={{ height: this.state.height }}
        className={[
          style.animatedBackground,
          this.props.hasCar && i == 0 ? style.hasCar : undefined,
          this.props.className ? this.props.className : undefined,
        ].join(" ")}
      >
        {/* <img
          width="40px"
          src={CDN_LINK + "/assets/images/redCar.png"}
          alt=""
        /> */}
      </div>
    ));
  }
}

export default BoxLoading;
