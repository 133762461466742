import fetcher from "../../common/axiosInstance";
import { loading } from "./commonActions";
import {
  GET_HOME_PAGE_DATA_NEW,
  UPDATE_HOME_TAB,
  GET_HOME_PAGE_NEW,
  GET_HOME_PAGE_USED,
} from "../types";

//Load homepage data from api
export const getHomePageData =
  (includes, callBack = null, pageType, req) =>
  async (dispatch) => {
    const res = await fetcher(req).get(
      `/site/new-home?thumb_size=300&include=${includes},odometer_ranges`
    ).catch((err) => {
        return err?.response
    });
    if (res?.data && res.data.success) {
      switch (pageType) {
        case 1:
          dispatch({
            type: GET_HOME_PAGE_NEW,
            payload: res.data.data,
          });
          break;
        case 2:
          dispatch({
            type: GET_HOME_PAGE_USED,
            payload: res.data.data,
          });
          break;
        default:
          dispatch({
            type: GET_HOME_PAGE_DATA_NEW,
            payload: res.data.data,
          });
          if (callBack) callBack();
      }
    }
  };

export const getIndexData =
  (includes, callback, pageType, req) => async (dispatch) => {
    dispatch(loading(true));
    await dispatch(getHomePageData(includes, callback, pageType, req));

    dispatch(loading(false));
  };

export const updateSelectedTab = (value) => async (dispatch) => {
  dispatch({
    type: UPDATE_HOME_TAB,
    payload: value,
  });
};
