import React from "react";
var __jsx = React.createElement;
import "../../_FIX_ME_STYLES/home.css";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import HomePage from "../../components/homePage/HomePage";
import { getIndexData, updateSelectedTab } from "@/redux/actions/homeActions";
import { getSearchSuggestions as _getSearchSuggestions } from "@/redux/actions/searchActions";
import { createHomeSeoUrl, createInternalUrlWithParams, setHeaderTags } from "@/utils/common";
import stl from "./index.module.css";
import { useLang } from "@/common/Contexts/LanguageContext";
import useIsMobile from "@hooks/useIsMobile";
import { HOMEPAGE_TITLE_NEW, HOMEPAGE_TITLE_USED } from "@/pages/home/homeInitialData";
function Home() {
  var lang = useLang();
  var history = useNavigate();
  var dispatch = useDispatch();
  var state = useSelector(function (state) {
    return state;
  });
  var _useState = useState(false),
    flashLoading = _useState[0],
    setFlashLoading = _useState[1];
  var location = useLocation();
  var isNew = decodeURI(location.pathname).indexOf("/home/new-cars") >= 0;
  var mobile_post_count = useIsMobile() ? 3 : 8;
  var USED_INCLUDES = "home:is_online(1):is_new(0),testimonial_videos,contact_us,mobile_posts:count(".concat(mobile_post_count, "):is_new(0),default_post:count(").concat(mobile_post_count, "):is_new(0):is_online(1),heros:is_new(0):is_online(1):hero_thumb_size(683),deal_offers:is_new(0):is_online(1):type(web),faq,users_feedback,heros_area:is_new(0),milage_ranges,price_ranges:is_new(0),homepage_sliders:is_new(0)");
  var NEW_INCLUDES = "home:is_online(1):is_new(1),testimonial_videos,contact_us,mobile_posts:count(".concat(mobile_post_count, "):is_new(1),default_post:count(").concat(mobile_post_count, "):is_new(1):is_online(1),heros:is_new(1):is_online(1):hero_thumb_size(683),deal_offers:is_new(1):is_online(1):type(web),faq,users_feedback,heros_area:is_new(1),milage_ranges,price_ranges:is_new(1),homepage_sliders:is_new(1)");
  useEffect(function () {
    if (state.home.homeDataNew.post_online_count == "") {
      var includes = USED_INCLUDES;
      if (isNew) {
        includes = NEW_INCLUDES;
      }
      dispatch(getIndexData(includes));
    }
  }, []);
  var link = createHomeSeoUrl(true, decodeURI(location.pathname), lang);
  var logoLinks = createHomeSeoUrl(false, decodeURI(location.pathname), lang);
  return __jsx(Layout, {
    hasNavBar: true,
    pageName: "home",
    hasMobFooter: true,
    homeSearchHeader: true,
    loading: state.common.loading,
    hideAdvSearchMobile: true,
    disableLoader: true,
    hasContactUs: true,
    createLanguageSwitcherLink: link,
    createLogoLanguageLink: logoLinks,
    updateTabs: function updateTabs(val) {
      if (state.home.selectedTab !== val) {
        var path = "/";
        var includes = USED_INCLUDES;
        if (val == 1) {
          path = "/home/new-cars";
          includes = NEW_INCLUDES;
        }
        history(createInternalUrlWithParams(path));
        dispatch(updateSelectedTab(val));
        dispatch(getIndexData(includes, false));
        setFlashLoading(true);
        window.scrollTo(0, 0);
      }
    },
    contact: state.common.contactUs,
    loggedin: state.auth.loggedin,
    notification: state.auth.notification,
    showPopCampaign: true,
    authenticationMsg: true,
    mainClass: [state.common.loading ? stl.flashLoading : undefined, stl.trans].join(" "),
    revampedHEading: true,
    getSearchSuggestions: function getSearchSuggestions(values, callBack, initialData) {
      dispatch(_getSearchSuggestions(values, callBack, initialData));
    }
    // hideOrangeHeadBtn
  }, setHeaderTags(isNew ? HOMEPAGE_TITLE_NEW[lang] : HOMEPAGE_TITLE_USED[lang]), __jsx(HomePage, {
    data: state.home.homeDataNew,
    isNew: isNew,
    isUsed: false,
    marketingData: state.home.marketingData,
    selectedTab: state.home.selectedTab,
    flashLoading: state.common.loading
  }));
}
export default Home;