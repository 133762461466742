import _extends from "/usr/app/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/usr/app/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["progressiveSmallImage", "waitUntilInteractive", "noInitialDiv", "errorImage", "src", "hasZoom", "loading"];
import React from "react";
var __jsx = React.createElement;
import { useState, useEffect } from "react";
import useIsFirstInteraction from "../common/hooks/useIsFirstInteraction";
import { CDN_LINK } from "@globalConfig";
import ZoomableImage from "@/components/carTracking/components/newPostBigSlider/components/ZoomableImage";
// Extends img element attributes to include custom props for smallImage and bigImage
var DEFAULT_ERROR_IMAGE = CDN_LINK + "/assets/images/no_img.jpg";
var MAX_ERRORS_COUNT = 3;
var counters = {};
var onError = function onError(imagePath, errorImage) {
  return function (_ref) {
    var currentTarget = _ref.currentTarget;
    counters[imagePath] = counters[imagePath] || 0;
    if (counters[imagePath] >= MAX_ERRORS_COUNT) {
      return;
    }
    counters[imagePath]++;
    currentTarget.onerror = null; // prevents looping
    currentTarget.src = errorImage || DEFAULT_ERROR_IMAGE;
  };
};
var ProgressiveImage = function ProgressiveImage(_ref2) {
  var _ref2$progressiveSmal = _ref2.progressiveSmallImage,
    progressiveSmallImage = _ref2$progressiveSmal === void 0 ? null : _ref2$progressiveSmal,
    _ref2$waitUntilIntera = _ref2.waitUntilInteractive,
    waitUntilInteractive = _ref2$waitUntilIntera === void 0 ? false : _ref2$waitUntilIntera,
    _ref2$noInitialDiv = _ref2.noInitialDiv,
    noInitialDiv = _ref2$noInitialDiv === void 0 ? false : _ref2$noInitialDiv,
    errorImage = _ref2.errorImage,
    src = _ref2.src,
    _ref2$hasZoom = _ref2.hasZoom,
    hasZoom = _ref2$hasZoom === void 0 ? false : _ref2$hasZoom,
    loading = _ref2.loading,
    imgProps = _objectWithoutProperties(_ref2, _excluded);
  var initialSrc = waitUntilInteractive ? null : src;
  // const Tag = hasZoom ? "ZoomableImage" : "";
  var _useState = useState(initialSrc),
    currentImage = _useState[0],
    setCurrentImage = _useState[1];
  var _useState2 = useState(loading),
    currentLoading = _useState2[0],
    setCurrentLoading = _useState2[1]; // Initialize with the provided loading prop
  var isFirstInteraction = useIsFirstInteraction();
  useEffect(function () {
    if (waitUntilInteractive && isFirstInteraction) {
      setCurrentImage(src);
      // setCurrentLoading(undefined); // Update loading prop
    }
  }, [isFirstInteraction]);
  useEffect(function () {
    setCurrentImage(src);
    //  setCurrentLoading(undefined); // Update loading prop
  }, [src]); // Add loading to dependencies
  // to Handel SVG
  if (!currentImage) {
    if (imgProps.width && imgProps.height) {
      return __jsx("div", {
        className: imgProps.className,
        style: {
          width: noInitialDiv ? "auto" : imgProps.width + "px",
          height: noInitialDiv ? "auto" : imgProps.height + "px",
          backgroundColor: "transparent",
          display: "inline-block"
        }
      });
    }
    return null;
  }
  return hasZoom ? __jsx(ZoomableImage, null, __jsx("img", _extends({
    className: "hasEvents",
    src: currentImage
    // @ts-ignore
    ,

    style: {
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundImage: progressiveSmallImage ? "url(\"".concat(progressiveSmallImage, "\")") : "none",
      backgroundColor: "transparent",
      backgroundPosition: "center"
    }
    // @ts-ignore
    ,

    loading: currentLoading // Use currentLoading state
  }, imgProps))) : __jsx("img", _extends({
    src: currentImage
    // @ts-ignore
    ,

    style: {
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundImage: progressiveSmallImage ? "url(\"".concat(progressiveSmallImage, "\")") : "none",
      backgroundColor: "transparent",
      backgroundPosition: "center"
    }
    // @ts-ignore
    ,

    loading: currentLoading // Use currentLoading state
  }, imgProps));
};
export default ProgressiveImage;