import { getIndexData, updateSelectedTab } from "@/redux/actions/homeActions";
import { CDN_LINK, serverUrl } from "@globalConfig";
import { thumbUrl } from "@/utils/imagesHelpers";
import { robotsIndexFollow } from "@/server/renderer/seo_renderer";
export var HOMEPAGE_TITLE_USED = {
  ar: "سيارات للبيع في السعودية | موقع سيارة",
  en: "Cars for Sale in Saudi Arabia | Syarah"
};
export var HOMEPAGE_TITLE_NEW = {
  ar: "سيارات جديدة للبيع في السعودية - أسعار السيارات الجديدة | سيارة",
  en: "New Cars For Sale in Saudi Arabia - Best Prices | Syarah"
};
export var homeInitialData = function homeInitialData(_ref) {
  var store = _ref.store,
    req = _ref.req;
  var url = decodeURI(req.url);
  var isNew = url.includes("/home/new-cars") || url.includes("/en/home/new-cars");
  var mobile_post_count = req.isMobile ? 3 : 8;
  var USED_INCLUDES = "home:is_online(1):is_new(0),testimonial_videos,contact_us,mobile_posts:count(".concat(mobile_post_count, "):is_new(0),default_post:count(").concat(mobile_post_count, "):is_new(0):is_online(1),heros:is_new(0):is_online(1):hero_thumb_size(683),deal_offers:is_new(0):is_online(1):type(web),faq,users_feedback,heros_area:is_new(0),milage_ranges,price_ranges:is_new(0),homepage_sliders:is_new(0)");
  var NEW_INCLUDES = "home:is_online(1):is_new(1),testimonial_videos,contact_us,mobile_posts:count(".concat(mobile_post_count, "):is_new(1),default_post:count(").concat(mobile_post_count, "):is_new(1):is_online(1),heros:is_new(1):is_online(1):hero_thumb_size(683),deal_offers:is_new(1):is_online(1):type(web),faq,users_feedback,heros_area:is_new(1),milage_ranges,price_ranges:is_new(1),homepage_sliders:is_new(1)");
  var selectedTab = 0;
  var includes = USED_INCLUDES;
  if (isNew) {
    selectedTab = 1;
    includes = NEW_INCLUDES;
  }
  if (url === "/جديدة" || url === "/en/new-cars") {
    return Promise.reject({
      type: "redirect",
      statusCode: 301,
      redirectUrl: "/autos",
      reason: "homeInitialData"
    });
  }
  return Promise.all([store.dispatch(getIndexData(includes, null, null, req)), store.dispatch(updateSelectedTab(selectedTab))]);
};
var processPreloadImages = function processPreloadImages(req, store) {
  var _homeDataNew$heros, _homeDataNew$heros$, _homeDataNew$heros2, _homeDataNew$heros2$, _homeDataNew$homepage, _homeDataNew$homepage2, _homeDataNew$homepage3, _homeDataNew$homepage4;
  var homeDataNew = store.getState().home.homeDataNew;
  var preloadImages = [];
  preloadImages.push(req.isMobile ? thumbUrl(homeDataNew === null || homeDataNew === void 0 ? void 0 : (_homeDataNew$heros = homeDataNew.heros) === null || _homeDataNew$heros === void 0 ? void 0 : (_homeDataNew$heros$ = _homeDataNew$heros[0]) === null || _homeDataNew$heros$ === void 0 ? void 0 : _homeDataNew$heros$.banner_img_mobile, "hero-v1", "0x154", true) : thumbUrl(homeDataNew === null || homeDataNew === void 0 ? void 0 : (_homeDataNew$heros2 = homeDataNew.heros) === null || _homeDataNew$heros2 === void 0 ? void 0 : (_homeDataNew$heros2$ = _homeDataNew$heros2[0]) === null || _homeDataNew$heros2$ === void 0 ? void 0 : _homeDataNew$heros2$.banner_img_desktop, "hero-v1", "0x300", true));
  preloadImages.push(thumbUrl(homeDataNew === null || homeDataNew === void 0 ? void 0 : (_homeDataNew$homepage = homeDataNew.homepage_sliders) === null || _homeDataNew$homepage === void 0 ? void 0 : (_homeDataNew$homepage2 = _homeDataNew$homepage[0]) === null || _homeDataNew$homepage2 === void 0 ? void 0 : (_homeDataNew$homepage3 = _homeDataNew$homepage2.images) === null || _homeDataNew$homepage3 === void 0 ? void 0 : (_homeDataNew$homepage4 = _homeDataNew$homepage3[0]) === null || _homeDataNew$homepage4 === void 0 ? void 0 : _homeDataNew$homepage4.image, "homepage-usp-slider-images-v1", req.isMobile ? "0x154" : "0x300"));
  return preloadImages.filter(Boolean);
};
export var seoData__home = function seoData__home(_ref2) {
  var req = _ref2.req,
    store = _ref2.store;
  var seoData = {};
  var decodedReq = decodeURI(req.url);
  var title = HOMEPAGE_TITLE_USED['ar'];
  var description = "اشتري سيارتك بسهولة و من أي مكان في السعودية. أوسع تشكيلة من السيارات الجديدة والمستعملة. سياراتنا المستعملة مفحوصة ومضمونة جربها وإن ما جازتلك رجعها.";
  var ogTitle = "\u0627\u0634\u062A\u0631 \u0633\u064A\u0627\u0631\u0629 \u0645\u0633\u062A\u0639\u0645\u0644\u0629 \u0645\u0636\u0645\u0648\u0646\u0629 \u0648\u0627\u0633\u062A\u0644\u0645\u0647\u0627 \u0639\u0646\u062F \u0628\u0627\u0628 \u0628\u064A\u062A\u0643 | \u0645\u0648\u0642\u0639 \u0633\u064A\u0627\u0631\u0629";
  var ogDescription = "\u0627\u0634\u062A\u0631 \u0633\u064A\u0627\u0631\u062A\u0643 \u0645\u0646 \u062C\u0648\u0627\u0644\u0643 \u0648\u0645\u0646 \u0623\u064A \u0645\u0643\u0627\u0646 \u0641\u064A \u0627\u0644\u0645\u0645\u0644\u0643\u0629. \u0633\u064A\u0627\u0631\u0627\u062A\u0646\u0627 \u0627\u0644\u0645\u0633\u062A\u0639\u0645\u0644\u0629 \u2714 \u0645\u0641\u062D\u0648\u0635\u0629 \u0644\u0623\u0643\u062B\u0631 \u0645\u0646 200 \u0646\u0642\u0637\u0629 \u2714 \u0648\u0645\u0636\u0645\u0648\u0646\u0629 \u0633\u0646\u0629 \u0643\u0627\u0645\u0644\u0629 \u2714 \u062A\u062C\u0631\u0628\u0647\u0627 10 \u0623\u064A\u0627\u0645 \u0648\u0625\u0646 \u0645\u0627 \u062C\u0627\u0632\u062A\u0644\u0643 \u0631\u062C\u0639\u0647\u0627.";
  var image = CDN_LINK + "/assets/images/og/10_days_ar.jpg";
  var canonical = '/';
  if (decodedReq === "/en") {
    title = HOMEPAGE_TITLE_USED['en'];
    description = "Buy your car with confidence anywhere in Saudi Arabia. Wide selection of new and used cars. Our used cars are inspected & guaranteed with 10-day refund guarantee.";
    ogTitle = "Explore Certified Used Cars Delivered to Your Doorstep | Syarah";
    ogDescription = "Buy your car online and from anywhere in the Kingdom. Our used cars are \u2714 inspected for +200 points \u2714 guaranteed for 1 year \u2714 with 10 Days Refund Guarantee.";
    image = CDN_LINK + "/assets/images/og/10_days_en.jpg";
    canonical = '/en';
  }
  //new-cars
  else if (decodedReq === "/home/new-cars") {
    title = HOMEPAGE_TITLE_NEW['ar'];
    description = "سيارات جديدة للبيع في السعودية ✔ ضمان الوكالة ✔ كاش وأقساط ✔ أفضل الأسعار على مستوى المملكة, تصفح الموقع الآن!";
    ogTitle = "\u062A\u0635\u0641\u062D \u0623\u0648\u0633\u0639 \u062A\u0634\u0643\u064A\u0644\u0629 \u0633\u064A\u0627\u0631\u0627\u062A \u062C\u062F\u064A\u062F\u0629 \u0646\u0648\u0635\u0644\u0647\u0627 \u0644\u0628\u0627\u0628 \u0628\u064A\u062A\u0643 | \u0645\u0648\u0642\u0639 \u0633\u064A\u0627\u0631\u0629";
    ogDescription = "\u062A\u0635\u0641\u062D \u0623\u0648\u0633\u0639 \u062A\u0634\u0643\u064A\u0644\u0629 \u0633\u064A\u0627\u0631\u0627\u062A \u062C\u062F\u064A\u062F\u0629 \u0641\u064A \u0627\u0644\u0633\u0639\u0648\u062F\u064A\u0629 \u2714 \u0636\u0645\u0627\u0646 \u0627\u0644\u0648\u0643\u0627\u0644\u0629 \u2714 \u0643\u0627\u0634 \u0648\u0623\u0642\u0633\u0627\u0637 \u2714 \u0623\u0641\u0636\u0644 \u0627\u0644\u0623\u0633\u0639\u0627\u0631. \u0627\u0634\u062A\u0631\u0647\u0627 \u0645\u0646 \u0633\u064A\u0627\u0631\u0629 \u0627\u0644\u0622\u0646!";
    image = CDN_LINK + "/assets/images/og/new_cars_ar.jpg";
    canonical = '/home/new-cars';
  } else if (decodedReq === "/en/home/new-cars") {
    title = HOMEPAGE_TITLE_NEW['en'];
    description = "New cars for sale in Saudi Arabia ✔ Cash and installments ✔ Agency warranty ✔  Best prices across the kingdom, browse Syarah website now!";
    ogTitle = "Browse the Widest Variety of New Cars in KSA | Syarah";
    ogDescription = "Browse the widest selection of new cars in Saudi Arabia \u2714 dealer warranty \u2714 cash and installment options \u2714 best prices. Buy it from Syarah now!";
    image = CDN_LINK + "/assets/images/og/new_cars_en.jpg";
    canonical = '/en/home/new-cars';
  }
  seoData.canonical = serverUrl + canonical;
  seoData.metaTitle = title;
  seoData.metaDescription = description;
  seoData.openGraph = {
    title: ogTitle,
    description: ogDescription,
    type: "website",
    image: image,
    url: serverUrl + req.url
  };
  seoData.structuredData = {
    BreadcrumbList: req.language === "en" ? '{ "@context": "https://schema.org/", "@type": "BreadcrumbList", "itemListElement": [{ "@type": "ListItem", "position": 1, "name": "Home Page", "item": "https://syarah.com/en/" }] }' : '{ "@context": "https://schema.org/", "@type": "BreadcrumbList", "itemListElement": [{ "@type": "ListItem", "position": 1, "name": "الرئيسية", "item": "https://syarah.com" }] }',
    website: req.language === "en" ? '{ "@context": "https://schema.org", "@type": "WebSite", "url": "https://syarah.com/en", "potentialAction": { "@type": "SearchAction", "target": "https://syarah.com/en/filters?text={search_term_string}&is_online=1", "query": "required", "query-input": "required name=search_term_string"}}' : '{ "@context": "https://schema.org", "@type": "WebSite", "url": "https://syarah.com/", "potentialAction": { "@type": "SearchAction", "target": "https://syarah.com/filters?text={search_term_string}&is_online=1", "query": "required", "query-input": "required name=search_term_string"}}'
  };
  seoData.robots = robotsIndexFollow(req.query, false);
  seoData.alternateLanguages = {
    followCanonical: true
  };
  seoData.preloadImages_USE_THIS_ONLY = processPreloadImages(req, store);
  return seoData;
};